import { Button, ExclamationMark, Skeleton } from 'components';
import { useSearchParams } from 'react-router-dom';
import { classes } from 'utils';

export const WalletConectbanner = ({ className }: { className?: string }) => {
  const [_, setSearchParams] = useSearchParams();
  return (
    <>
      <div
        className={classes(
          'flex md:flex-row flex-col md:justify-between md:items-center md:gap-4 gap-[14px] w-full py-5 px-6 rounded-lg border border-solid border-[#fc3b46cc] bg-[linear-gradient(0deg,_var(--tw-gradient-stops))] from-[#fc3b460f_0%] to-[#fc3b460f_100%] backdrop-blur-sm',
          className
        )}
      >
        <div className="flex gap-4">
          <div className="flex justify-center items-center md:p-3 p-[10px] rounded-md bg-[#ffffff12] md:max-h-12 max-h-10 md:max-w-12 max-w-10">
            <Skeleton.Loader className="h-6 w-6">
              <ExclamationMark stroke="#FC3B46" className="h-6 w-6" />
            </Skeleton.Loader>
          </div>
          <div className="flex flex-col md:justify-between md:gap-0 gap-1">
            <Skeleton.Loader className="h-4 md:w-[25vw] w-[150px]">
              <div className="md:text-lg text-[16px] text-white">
                Lost Access to Your Connected Substrate Wallet?
              </div>
            </Skeleton.Loader>
            <Skeleton.Loader className="h-4 md:w-[30vw] sm:w-[200px] w-[150px]">
              <div className="md:text-base text-sm leading-5 text-gray">
                Connect a new Substrate wallet to get whitelisted for one of the next reward batches
                before the deadline to 28th Jan UTC 00:00. For more info, please check our{' '}
                <a
                  href="https://x.com/OneAnalog/status/1881059103084322988"
                  target="_blank"
                  className="underline underline-offset-[6px] pb-2 cursor-pointer"
                  rel="noreferrer"
                >
                  blog post
                </a>
                .
              </div>
              <div className="md:text-base text-sm leading-5 text-gray">
                Note: This process will not impact your ATP score and is only needed for
                verification to claim your rewards on the claims portal. Once connected, you
                won&apos;t be able to change your wallet again.
              </div>
            </Skeleton.Loader>
          </div>
        </div>
        <Skeleton.Loader className="h-10 md:[70px] md:w-[100px] w-full">
          <Button
            variant="light"
            classNames={{
              base: 'md:h-10 h-9 w-[94px] md:w-fit w-full',
              container: 'text-xs md:text-sm',
            }}
            onClick={() => setSearchParams({ 'switch-wallet': 'true' })}
          >
            Connect Wallet
          </Button>
        </Skeleton.Loader>
      </div>
    </>
  );
};
