import {
  Button,
  CustomLink,
  VCalenderIcon,
  VCandleIcon,
  VDoc2Icon,
  VDownlodIcon,
  VFeedbackIcon,
  VHashtagIcon,
  VLocationIcon,
  VMirrorIcon,
  VSidebarIcon,
  VTaskIcon,
  VWalletIcon,
  VWifiIcon,
} from 'components';

export const useValidatorQuest = () => {
  return [
    {
      heading: 'Phase 1: Validator Setup',
      headCta: (
        <Button
          className="brightness-200"
          classNames={{
            base: 'md:h-[38px] h-[34px] md:!px-4 !px-4',
            container: 'md:text-sm text-xs',
          }}
        >
          Ended
        </Button>
      ),
      tasks: [
        {
          icon: <VWifiIcon />,
          title: 'Generate a wallet address (e.g. using Polkadot Vault)',
        },
        {
          icon: <VWalletIcon />,
          title: 'Fund new wallet with $TANLOG from the faucet',
        },
        {
          icon: <VCandleIcon />,
          title: 'Setup address as a validator on-chain',
        },
        {
          icon: <VDownlodIcon />,
          title: 'Download and start a validator node',
          description: (
            <div className="whitespace-pre-line">
              <ul className="[list-style:disc] pl-6">
                <li>Ensure the machine follows the recommendations</li>
                <li>Add basic monitoring, alerts and backups</li>
                <li>Check that the nodes appears on telemetry</li>
                <li>Fully sync current chain data</li>
              </ul>
            </div>
          ),
        },
        {
          icon: <VDoc2Icon />,
          title: 'Generate session keys and register them on-chain',
        },
        {
          icon: <VTaskIcon />,
          title: 'Submit whitelist request',
          description: (
            <div className="whitespace-pre-line">
              <ul className="[list-style:disc] pl-6">
                <li>Specify node name</li>
                <li>Specify stash address</li>
                <li>Contact information</li>
              </ul>
            </div>
          ),
        },
      ],
    },
    {
      heading: 'Phase 2: Validator Score',
      tasks: [
        {
          icon: <VLocationIcon />,
          title: 'Run a validator in a unique location or region',
        },
        {
          icon: <VMirrorIcon />,
          title: 'Make sure the validator is always online',
        },
        {
          icon: <VCandleIcon />,
          title: 'Ensure you trigger the payout regularly',
        },
        {
          icon: <VCalenderIcon />,
          title: (
            <div>
              Ensure you keep your node up to date (follow the announcement channel{' '}
              <CustomLink link="https://discord.com/channels/860069399627825163/1242304290740244561">
                #validator-support
              </CustomLink>{' '}
              on our Discord)
            </div>
          ),
        },
      ],
    },
    {
      heading: 'Phase 3: Additional Rewards',
      withoutTopMargin: true,
      tasks: [
        {
          icon: <VFeedbackIcon />,
          title: 'Please provide us with feedback on your experience as a validator Time Node',
          description: 'Complete the form and be sure to give us quality responses.',
          taskButton: (
            <Button
              className="brightness-200"
              classNames={{
                base: `h-[unset] px-3 !py-[9px] lg:!w-[172px] !w-full lg:!max-w-[172px]`,
                container: 'md:text-sm text-xs md:leading-[22px] leading-[18px]',
              }}
            >
              Ended
            </Button>
          ),
        },
        {
          icon: <VSidebarIcon />,
          title:
            'Contribute to our testnet with infrastructure tooling for the Analog ecosystem (dev tools, scripts etc.)',
          description: 'Please submit this through the linked repository.',
          taskButton: (
            <Button
              className="brightness-200"
              classNames={{
                base: `h-[unset] px-3 !py-[9px] lg:!w-[172px] !w-full lg:!max-w-[172px]`,
                container: 'md:text-sm text-xs md:leading-[22px] leading-[18px]',
              }}
            >
              Ended
            </Button>
          ),
        },
        {
          icon: <VHashtagIcon />,
          title:
            'Contribute to our testnet with usability content specific to validator operations (validator setup tutorials, doc improvements, nominations, claiming rewards etc.)',
          description: 'Please submit this through the linked repository.',
          taskButton: (
            <Button
              className="brightness-200"
              classNames={{
                base: `h-[unset] px-3 !py-[9px] lg:!w-[172px] !w-full lg:!max-w-[172px]`,
                container: 'md:text-sm text-xs md:leading-[22px] leading-[18px]',
              }}
            >
              Ended
            </Button>
          ),
        },
      ],
    },
  ];
};
