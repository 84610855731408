import { Modal } from './Modal';
import { Setter } from 'types';
import { Header } from './Header';
import { Button } from './Button';

const UpdatedToS = ({
  isConfirmed,
  setIsConfirmed,
}: {
  isConfirmed: boolean;
  setIsConfirmed: Setter<boolean>;
}) => {
  return (
    <Modal
      isOpen={!isConfirmed}
      classNames={{ base: 'w-full z-50 bg-black md:p-8 p-6', modalBase: 'mt-0 max-h-full' }}
      isCloseHidden
    >
      <div className="w-full">
        <Header classNames={{ header: 'capitalize' }}>Update to Terms of Service!</Header>
        <div className="flex flex-col gap-2 text-gray mt-1 overflow-y-scroll scrollbar-white pr-2">
          <p>
            We&apos;ve updated our Terms of Service! The timeframe to claim your reward has been
            extended to 6 months. Please review the updated{' '}
            <a
              href={window.location.origin + '/terms-of-service.pdf'}
              target="_blank"
              rel="noreferrer"
              className="bg-primary-gradient bg-clip-text text-transparent cursor-pointer"
            >
              terms
            </a>{' '}
            for full details.
          </p>
        </div>
        <Button variant="primary" className="w-full mt-6" onClick={() => setIsConfirmed(true)}>
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default UpdatedToS;
