import { Button } from 'components';
import { HTMLAttributes } from 'types';
import { useState } from 'react';

export interface QuestButtonProps extends HTMLAttributes<HTMLButtonElement> {
  handleClick?: (event?: React.MouseEvent<HTMLButtonElement>, isClicked?: boolean) => void;
  isLoading?: boolean;
  buttonTextAfterClick?: string | null;
  inactive?: boolean;
}

export const QuestTaskButton = (props: QuestButtonProps) => {
  const [isClicked, setIsClicked] = useState(false);
  return (
    <Button
      variant="light"
      classNames={{
        base: `h-[unset] px-3 !py-[9px] lg:!w-[172px] !w-full lg:!max-w-[172px] ${
          props.inactive && 'brightness-150'
        }`,
        container: 'md:text-sm text-xs md:leading-[22px] leading-[18px]',
      }}
      isLoading={props.isLoading}
      onClick={(e) => {
        props.handleClick && props.handleClick(e, isClicked);
        props.buttonTextAfterClick && setIsClicked(true);
      }}
    >
      {props.buttonTextAfterClick && isClicked ? props.buttonTextAfterClick : props.children}
    </Button>
  );
};

export interface VisitQuestButtonProps extends HTMLAttributes<HTMLButtonElement> {
  handleClick?: (event?: React.MouseEvent<HTMLButtonElement>, isClicked?: boolean) => void;
}

export const VisitQuestButton = (props: VisitQuestButtonProps) => {
  return (
    <button
      onClick={props.handleClick}
      className="flex justify-center items-center border border-[#49474A] rounded-lg h-[unset] px-3 py-[9px] lg:w-[148px] w-full lg:max-w-[148px] md:text-sm text-xs md:leading-[22px] leading-[18px]"
    >
      <div className="md:text-sm text-xs md:leading-[22px] leading-[18px]">{props.children}</div>
    </button>
  );
};

export const useCommunityQuests = () => {
  // const [{ accessToken }] = useCookies(['accessToken']);
  // const { evmWalletAddress, open } = useEVM();
  // const { account, trackEvent, userPoints, fetchUserPoints, fetchUser } = useApi();
  // const [_, setSearchParams] = useSearchParams();
  // const navigate = useNavigate();
  // const [questType, setQuestType] = useState<string>();

  // const [verifyProofOfHumanity, { loading: verifyProofOfHumanityLoading }] =
  //   useVerifyProofOfHumanityMutation({
  //     fetchPolicy: 'no-cache',
  //     onCompleted() {
  //       customToast('Proof of humanity verified!');
  //       trackEvent('Proof of humanity Verified');
  //       fetchUserPoints();
  //       fetchUser();
  //     },
  //     onError() {
  //       window.open('https://www.intract.io/proof-of-humanity?pohReferralCode=ANALOG', '_blank');
  //     },
  //   });

  // const [verifyDmailSendEmailQuest, { loading: verifyDmailSendEmailLoading }] =
  //   useVerifyDmailSendEmailQuestMutation({
  //     fetchPolicy: 'no-cache',
  //   });

  // const [claimQuaereQuest, { loading: claimQuaereQuestLoading }] = useClaimQuaerePointsMutation({
  //   fetchPolicy: 'no-cache',
  // });
  // const [claimZealyQuest, { loading: claimZealyQuestLoading }] = useClaimZealyQuestPointsMutation({
  //   fetchPolicy: 'no-cache',
  // });

  // const [claimGalaxePoints, { loading: claimPointsLoading, client }] = useClaimGalxePointsMutation({
  //   fetchPolicy: 'no-cache',
  // });

  // const [claimPixelportPoints, {loading: claimPixelportPointsLoading}] = useClaimPixelportPointsMutation({fetchPolicy: 'no-cache'})

  // const handleOATQuestVerify = (questType?: GalxeQuestType) => {
  //   if (evmWalletAddress) {
  //     if (accessToken && questType) {
  //       claimGalaxePoints({
  //         variables: { evmWalletAddress, accessToken, questType },
  //         refetchQueries: ['UserPoints', 'CheckGalaxyQuestCompleted'],
  //         onError(error) {
  //           customToast(`Verification failed: ${error.message}`, true);
  //         },
  //       });
  //       setQuestType(questType);
  //     }
  //   } else {
  //     open();
  //     client.getResolvers();
  //   }
  //   trackEvent('quest_clicks', {
  //     communityQuest: 'EVM Wallet Connect From Galxy Quest',
  //   });
  // };
  // const handleSentEmailOnDmailClaim = (_?: MouseEvent<HTMLButtonElement>, isClicked?: boolean) => {
  //   if (account?.walletAddress) {
  //     verifyDmailSendEmailQuest({
  //       variables: { accessToken },
  //       onCompleted() {
  //         fetchUserPoints();
  //       },
  //       onError(err) {
  //         if (!isClicked) {
  //           window.open('https://mail.dmail.ai', '_blank');
  //         } else {
  //           customToast(`Verification failed: ${err.message}`, true);
  //         }
  //       },
  //     });
  //     trackEvent('quest_clicks', {
  //       communityQuest: 'Dmail Quest Claim',
  //     });
  //   } else {
  //     setSearchParams({ 'connect-wallet': 'true' });
  //   }
  // };

  // const handleQuaereQuestClaim = (_?: MouseEvent<HTMLButtonElement>, isClicked?: boolean) => {
  //   claimQuaereQuest({
  //     variables: { accessToken },
  //     onCompleted() {
  //       fetchUserPoints();
  //     },
  //     onError(err) {
  //       if (!isClicked) {
  //         window.open('https://www.quaere.ai/', '_blank');
  //       } else {
  //         customToast(`Verification failed: ${err.message}`, true);
  //       }
  //     },
  //   });
  //   trackEvent('quest_clicks', {
  //     communityQuest: 'Dmail Quest Claim',
  //   });
  // };

  // const handleZealyQuestClaim = (_?: MouseEvent<HTMLButtonElement>, isClicked?: boolean) => {
  //   if (account?.connectedDiscordServer) {
  //     claimZealyQuest({
  //       variables: { accessToken },
  //       onCompleted() {
  //         fetchUserPoints();
  //       },
  //       onError(err) {
  //         if (!isClicked) {
  //           window.open(
  //             'https://zealy.io/cw/analog/questboard/5078a01b-2cde-4239-b57c-5f6f4a974e7c/5b14d937-12c8-4ad4-b794-9f3013293100',
  //             '_blank'
  //           );
  //         } else {
  //           customToast(`Verification failed: ${err.message}`, true);
  //         }
  //       },
  //     });
  //     trackEvent('quest_clicks', {
  //       communityQuest: 'Zealy Quest Claim',
  //     });
  //   } else {
  //     window.location.href = `${import.meta.env.VITE_BACKEND_ENDPOINT}auth/verifyDiscordGuild`;
  //   }
  // };

  // const handlePixelportQuestClaim = (_?: MouseEvent<HTMLButtonElement>, isClicked?: boolean) => {
  //   if (evmWalletAddress) {
  //     if (accessToken) {
  //       claimPixelportPoints({
  //         variables: { evmWalletAddress, accessToken },
  //         refetchQueries: ['UserPoints'],
  //         onError(error) {
  //           customToast(`Verification failed: ${error.message}`, true);
  //           if (!error.message.includes('10')) {
  //             window.open('https://pixelport.xyz/', '_blank');
  //           }
  //         },
  //       });
  //       setQuestType(questType);
  //     }
  //   } else {
  //     open();
  //   }
  //   trackEvent('quest_clicks', {
  //     communityQuest: 'EVM Wallet Connect From Pixelport Quest',
  //   });
  // }

  return null;
};
