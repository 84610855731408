import { useEffect, useMemo, useState } from 'react';
import { Tab } from '@headlessui/react';
import {
  BillIcon,
  BreadCrumbs,
  Button,
  ChatIcon,
  GradientRefreshIcon,
  Header,
  LeaderboardIcon,
  MedalStarIcon,
  Overview,
  QuestOver,
  SettingIcon,
  Skeleton,
  Star2Icon,
} from 'components';

import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTabs, useWindowSize } from 'hooks';
import { useApi } from 'context';
import { useEVM, useGMPQuests } from 'hooks/quests';
import { useQuestPageData } from './useQuestPageData';
import { classes } from 'utils';
import ValidatorsQuestCard from './ValidatorsQuestCard';
import { useValidatorQuest } from 'hooks/quests/useValidtorQuests';
// import { SocialConnectBanner } from 'components/SocialConnectBanner';
import { encodeAddress } from '@polkadot/util-crypto';
import { useValidatorLeaderBoardQuery } from 'gql';
import InactiveQuest from './InactiveQuest';

enum QuestTabType {
  Community = 0,
  Developer = 1,
  Validator = 2,
}

const getTabHeader = (tab: number) => {
  switch (tab) {
    case 0:
      return 'Community Quests';
    case 1:
      return 'Developer Quests';
    case 2:
      return 'Validator Quests';
  }
};

export function Quests({ isInactive }: { isInactive?: boolean }) {
  const navigate = useNavigate();
  const { isMobile } = useWindowSize();
  const { account, queryCooldown, setQueryCooldown, fetchUserPoints } = useApi();
  const { isEvmWalletConnected, evmWalletAddress, open } = useEVM();
  const [_, setSearchParams] = useSearchParams();
  const gmpQuest = useGMPQuests();
  const {
    refetchDevQuestCount,
    fetchCompletedDevQuestCount,
    completedDevQuestsCount,
    completedQuests,
    userPoints,
    isUserPointsLoading,
    loadingQuestData,
  } = useQuestPageData();

  const [throttleLoading, setThrottleLoading] = useState(false);

  const { tabPanels, tabSelector, selectedTab, setSelectedTab } = useTabs(
    [
      {
        child: (
          <div className={`mt-0 md:mt-5 text-white flex flex-col md:gap-10 gap-6`}>
            {/* {useCommunityQuests().map((quest, index) => {
              if (!quest) return;

              return (
                <QuestCard
                  key={index}
                  quest={quest}
                  completedQuests={completedQuests}
                  hideAdditionalDescriptionAfterComplete
                  classNames={{
                    base: classes(
                      'lg:min-w-[126px]',
                      quest.widePoints &&
                        'flex justify-center items-center lg:min-w-[245px] md:min-w-[173px] max-w-[40vw]'
                    ),
                  }}
                />
              );
            })} */}
          </div>
        ),
        label: 'Community',
        value: 'community',
      },
      {
        child: (
          <div className="md:mt-10 mt-6 text-white flex flex-col md:gap-10 gap-6">
            {/* {useDeveloperQuests().map((quest, index) => {
              return (
                <QuestCard
                  key={index}
                  quest={quest}
                  connectWalletHandler={() => {
                    setSearchParams({ 'connect-wallet': 'true' });
                  }}
                  blockCondition={quest.blockCondition || false}
                  completedQuests={completedQuests}
                  completedCountData={completedDevQuestsCount?.completedDevQuestCount}
                  classNames={{
                    base: 'flex justify-center items-center lg:min-w-[126px] md:min-w-[108px] sm:min-w-[95px]',
                  }}
                />
              );
            })} */}
            {/* <QuestCard
              quest={gmpQuest}
              connectWalletHandler={() => open()}
              blockCondition={
                !account?.id ||
                !completedQuests.some((point) => point?.type === 'connectEVMWalletOnTestnet') ||
                !isEvmWalletConnected ||
                !evmWalletAddress
              }
              completedQuests={completedQuests}
              completedCountData={completedDevQuestsCount?.completedDevQuestCount}
              classNames={{
                base: 'flex justify-center items-center md:min-w-[126px] min-w-[89px]',
              }}
            /> */}
          </div>
        ),
        label: 'Developer',
        value: 'developer',
      },
      {
        child: (
          <div className="md:mt-5 mt-6 text-white flex flex-col md:gap-10 gap-6">
            {useValidatorQuest().map((quest, index) => {
              return <ValidatorsQuestCard key={index} quest={quest} />;
            })}
          </div>
        ),
        label: 'Validator',
        value: 'validator',
      },
    ],
    0,
    {
      classNames: {
        base: 'md:!h-10 w-full',
        radioClasses: 'md:!flex-[0] md:px-4 text-[14px] md:h-[30px] h-[26px] rounded-[7px]',
      },
    }
  );

  const { data: validatorLeaderBoard, loading: validatorLeaderBoardLoading } =
    useValidatorLeaderBoardQuery({ skip: selectedTab !== QuestTabType.Validator });

  const userLeaderBoardRow = useMemo(() => {
    if (account?.walletAddress) {
      const userAnalogAddress = encodeAddress(account?.walletAddress as string, 12850);
      return validatorLeaderBoard?.validatorLeaderBoard?.find(
        (row) => row?.address === userAnalogAddress
      );
    }
  }, [account?.walletAddress, validatorLeaderBoard?.validatorLeaderBoard]);

  useEffect(() => {
    if (
      selectedTab === QuestTabType.Developer &&
      !completedDevQuestsCount?.completedDevQuestCount
    ) {
      fetchCompletedDevQuestCount();
    }
  }, [selectedTab]);

  return (
    <Skeleton.Provider
      isLoading={loadingQuestData || throttleLoading || validatorLeaderBoardLoading}
    >
      <div className="flex flex-col w-full md:py-[60px] py-[36px]">
        {/* <SocialConnectBanner className="md:mb-[40px] mb-[32px]" /> */}
        <Overview
          sections={
            selectedTab === QuestTabType.Validator
              ? [
                  {
                    icon: <LeaderboardIcon className="h-6 w-6" />,
                    label: 'Ranking',
                    data: userLeaderBoardRow?.rank || '-',
                  },
                  {
                    icon: <SettingIcon className="h-6 w-6" />,
                    label: 'Validator Score',
                    data: userLeaderBoardRow ? Number(userLeaderBoardRow?.score).toFixed(2) : '0',
                  },
                  {
                    icon: <MedalStarIcon className="h-6 w-6" />,
                    label: 'Rewards',
                    data: userLeaderBoardRow ? Number(userLeaderBoardRow?.rewards).toFixed(2) : 0,
                    dataUnit: 'TANLOG',
                  },
                ]
              : [
                  {
                    icon: <BillIcon />,
                    label: 'Quests Completed',
                    data:
                      completedQuests.length +
                        (userPoints?.UserPoints?.viewShortListed ? 1 : 0) +
                        (userPoints?.UserPoints?.votingSessionParticipated ? 1 : 0) +
                        (userPoints?.UserPoints?.winnerAsDesigner ? 1 : 0) +
                        (userPoints?.UserPoints?.winnerAsVoter ? 1 : 0) || '0',
                  },
                  {
                    icon: <Star2Icon />,
                    label: 'Quest Points',
                    data:
                      (userPoints?.UserPoints?.total || 0) -
                        ((userPoints?.UserPoints?.referral || 0) +
                          (userPoints?.UserPoints?.expectedReferral || 0) +
                          (userPoints?.UserPoints?.earlyBonus || 0)) || '0',
                    dataUnit: 'ATP',
                  },
                ]
          }
          refreshButton={
            selectedTab !== QuestTabType.Validator && account?.id ? (
              <Button
                variant="light"
                className="z-50 h-[unset] !p-2"
                onClick={() => {
                  if (queryCooldown) {
                    setThrottleLoading(true);
                    setTimeout(() => {
                      setThrottleLoading(false);
                    }, 1000);
                  } else {
                    setQueryCooldown(true);
                    fetchUserPoints();
                    refetchDevQuestCount();
                  }
                }}
              >
                <GradientRefreshIcon
                  className={classes(
                    'h-5 w-5',
                    (isUserPointsLoading || throttleLoading) && 'refresh-animation'
                  )}
                />
              </Button>
            ) : undefined
          }
          classNames={{
            base: 'mb-[32px] md:mb-[45px]',
            sectionsContainer: classes(
              'md:flex grid grid-cols-2',
              isMobile &&
                '[&>*:nth-child(3)]:pl-0 [&>*:nth-child(3)]:border-none [&>*:nth-child(3)]:mt-3'
            ),
          }}
        />
        {isInactive && (
          <BreadCrumbs
            pages={[
              { page: 'Quests', label: 'Quest' },
              { page: 'InactiveQuests', label: 'Archived Quest' },
            ]}
          />
        )}
        <Tab.Group onChange={setSelectedTab}>
          <Header
            accessory={!isMobile && !isInactive && tabSelector}
            classNames={{
              header: 'lg:text-2xl text-optionsxl flex items-center',
              base: 'flex justify-between md:flex-row flex-col flex-wrap gap-4',
              accessory: 'md:w-fit md:!mb-0',
            }}
            isQuest
          >
            {isInactive ? 'Archieve Quest' : getTabHeader(selectedTab)}
          </Header>
          <p className="text-sm text-gray lg:w-[54%] w-full md:mt-2 mt-1 ">
            <Skeleton.Loader className="h-4 max-w-[580px]">
              {selectedTab === QuestTabType.Validator
                ? `Get started with the validator setup by following these simple steps.`
                : `Together, we can enable a multi-chain future with limitless possibilities. Become part
              of this movement by joining the Analog Community and earning more ATP points in the
              process.`}
            </Skeleton.Loader>
          </p>
          {isMobile && !isInactive && (
            <div className="w-full mt-4">
              <Skeleton.Loader className="w-full h-[38px]">{tabSelector}</Skeleton.Loader>
              <Button
                variant="plain"
                className={classes(
                  'uppercase',
                  'text-sm bg-primary-gradient bg-clip-text text-transparent leading-6 font-normal '
                )}
                onClick={() => {
                  navigate('/quests/inactive');
                }}
              >
                archived Quests
              </Button>
            </div>
          )}
          {selectedTab === QuestTabType.Developer && (
            <div className="rounded-lg md:mt-[20px] mt-4 flex md:flex-row flex-col md:items-center gap-4 border-[0.5px] border-solid bg-[#49474A] border-[#49474A] [background:linear-gradient(0deg,_#ffffff12_0%,_#ffffff12_100%),_#000] opacity-[0.3px] py-[21px] px-[24px]">
              <div className="flex gap-4 items-center">
                <Skeleton.Loader className="h-8 w-8">
                  <div className="rounded-[6px] max-h-[38px] w-[38px] p-[7px] bg-[#ffffff1a] flex justify-center items-center">
                    <ChatIcon />
                  </div>
                </Skeleton.Loader>
                <div className="flex items-start flex-col">
                  <div className="text-white">
                    <Skeleton.Loader className="h-5 w-48">
                      <div className="md:text-[18px] text-sm leading-6">
                        We Value Your Feedback!
                      </div>
                    </Skeleton.Loader>
                    <Skeleton.Loader className="h-5 w-[200%]">
                      <div className="md:text-[14px] text-xs leading-5 text-[#919191]">
                        Share your feedback with our team on Discord.
                      </div>
                    </Skeleton.Loader>
                  </div>
                </div>
              </div>
              <Skeleton.Loader
                className="md:w-[144px] w-full py-[24px] md:h-[38px] h-[36px]"
                containerClassName="ml-auto"
              >
                <Button
                  variant="light"
                  classNames={{
                    base: 'md:w-[144px] w-full py-[24px] ml-auto md:h-[38px] h-[36px]',
                    container: 'md:text-sm text-xs',
                  }}
                  onClick={() => window.open('https://discord.gg/analog', '_blank')}
                >
                  Share Feedback
                </Button>
              </Skeleton.Loader>
            </div>
          )}
          {isInactive ? <InactiveQuest /> : tabPanels}
        </Tab.Group>
      </div>
    </Skeleton.Provider>
  );
}
