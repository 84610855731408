import { useEffect, useMemo, useState } from 'react';
import { classes, pathTo } from 'utils';
import {
  BorderedGlobe,
  BrokenStar,
  Clock2Icon,
  DocumentIcon,
  FaucetIcon,
  GameLogo,
  GradientArrow,
  GradientQuestionIcon,
  HomeGradientIcon,
  HomeIcon,
  LeaderboardGradientIcon,
  LeaderboardIcon,
  Profile2Icon,
  ProfileIcon,
  QuestGradientIcon,
  QuestIcon,
  QuestionIcon,
  StarGradientIcon,
  TermsIcon,
  TutorialIcon,
  ValidatorsGradientIcon,
  ValidatorsIcon,
} from 'components';
import { ClassNames, Setter } from 'types';
import { Link, useLocation } from 'react-router-dom';
import { useWindowSize } from 'hooks';
import { useApi, useAuth } from 'context';

const OtherOptions = [
  {
    label: 'Terms of Service',
    Icon: TermsIcon,
    link: `${window.location.origin}/terms-of-service.pdf`,
  },
  {
    label: 'FAQs',
    Icon: QuestionIcon,
    link: pathTo('FAQ'),
    ActiveIcon: GradientQuestionIcon,
    pageNavigation: true,
  },
  { label: 'Contact', Icon: Profile2Icon, link: 'https://discord.gg/analog' },
  { label: 'Docs', Icon: DocumentIcon, link: 'https://docs.analog.one/documentation' },
];

export function SideBar({
  classNames,
  setIsOpen,
}: {
  classNames?: ClassNames<'content'>;
  setIsOpen: Setter<boolean>;
}) {
  const { account } = useApi();
  const { isAdmin, isSuccessLogin } = useAuth();
  const location = useLocation();
  const { isMobile } = useWindowSize();

  const [active, setActive] = useState(0);
  const gameOptions = useMemo(() => {
    const options = [
      {
        label: 'Home',
        Icon: HomeIcon,
        link: 'Home',
        ActiveIcon: HomeGradientIcon,
        isAccesible: true,
      },
      {
        label: 'Quests',
        Icon: QuestIcon,
        link: 'Quests',
        ActiveIcon: QuestGradientIcon,
        isAccesible: !!account?.id,
      },
      {
        label: 'Watch Game',
        Icon: BrokenStar,
        link: 'Game',
        ActiveIcon: StarGradientIcon,
        isAccesible: !!account?.id,
      },
      {
        label: 'Leaderboard',
        Icon: LeaderboardIcon,
        link: 'Leaderboard',
        ActiveIcon: LeaderboardGradientIcon,
        isAccesible: !!account?.id,
      },
      {
        label: 'Validators',
        Icon: ValidatorsIcon,
        link: 'ValidatorLeaderboard',
        ActiveIcon: ValidatorsGradientIcon,
        isAccesible: !!account?.id,
      },
      {
        label: 'Tutorials',
        Icon: TutorialIcon,
        link: 'https://teamanalog.notion.site/Testnet-Demo-Video-Resources-35dd7ab8a7c74a639a64d0a1c82f9c97?pvs=4',
        outSideLink: true,
        isAccesible: true,
      },
      {
        label: 'Watch Portal',
        Icon: Clock2Icon,
        link: import.meta.env.VITE_WATCH_FRONTEND_URL,
        outSideLink: true,
        isAccesible: true,
      },
      {
        label: 'Faucet',
        Icon: FaucetIcon,
        link: 'https://docs.analog.one/documentation/resources/testnet-faucet',
        outSideLink: true,
        isAccesible: true,
      },
    ];
    if (isAdmin) {
      return [
        ...options,
        {
          label: 'Admin Panel',
          Icon: ProfileIcon,
          link: 'AdminPanel',
          ActiveIcon: ProfileIcon,
          isAccesible: true,
        },
      ];
    }
    return options;
  }, [isAdmin, account]);

  useEffect(() => {
    setActive(
      gameOptions.findIndex(({ link }) =>
        pathTo(link).toLowerCase().includes(location.pathname.replace('/', '').split('/')[0])
      )
    );
  }, [location.pathname, gameOptions, isAdmin]);

  return (
    <div
      className={classes(
        'h-screen border-solid  border-0 border-r border-dark-gray bg-black overflow-hidden min-w-[225px] md:flex flex-col hidden  transition-[5s]',
        classNames?.base
      )}
    >
      <div
        className={classes('flex flex-col items-center text-white w-[225px]', classNames?.content)}
      >
        {!isMobile && (
          <div className="p-6 w-full">
            <GameLogo />
          </div>
        )}
        <div
          className={classes(
            'overflow-auto md:h-[calc(100svh_-_289px)] h-[calc(100svh_-_339px)] w-full scrollbar-white',
            isMobile && 'mt-[72px]',
            isMobile && !!account?.id && 'h-[calc(100svh_-_291px)]'
          )}
        >
          {gameOptions.map(({ Icon, label, link, ActiveIcon, isAccesible, outSideLink }, index) => {
            const showDisabled = isAccesible ? false : !isSuccessLogin;

            return (
              <Link
                to={outSideLink ? link : pathTo(link)}
                target={outSideLink ? '_blank' : ''}
                key={index}
                className={classes(
                  'flex flex-row gap-3 w-full h-12 items-center px-6 py-3 relative hover:after:left-0 hover:after:top-0 hover:after:h-full hover:after:w-[3px] hover:bg-[#ffffff0d] hover:after:bg-[#ffffff33] hover:after:rounded-tr-[1px] hover:after:rounded-br-[1px] hover:after:absolute group cursor-pointer',
                  active === index &&
                    'bg-primary-gradient-light relative after:left-0 after:top-0 after:h-full after:w-[3px] after:bg-primary-gradient after:rounded-tr-[1px] after:rounded-br-[1px] after:absolute ',
                  showDisabled && 'opacity-[0.4] cursor-not-allowed'
                )}
                onClick={(e) => {
                  if (!outSideLink) {
                    if (showDisabled) {
                      e.preventDefault();
                    } else {
                      setActive(index);
                      setIsOpen(false);
                    }
                  } else {
                    setIsOpen(false);
                  }
                }}
              >
                <div className="h-5 w-5 flex items-center justify-center">
                  {active === index && ActiveIcon ? (
                    <ActiveIcon className="min-w-5 min-h-5 max-w-5 max-h-5" />
                  ) : (
                    <Icon className="min-w-5 min-h-5 max-w-5 max-h-5" />
                  )}
                </div>
                <div
                  className={classes(
                    'text-sm',
                    active === index && 'bg-primary-gradient bg-clip-text text-transparent'
                  )}
                >
                  {label}
                </div>
              </Link>
            );
          })}
        </div>
      </div>
      <div className="flex flex-col items-center text-white  border-0 border-t  border-solid border-[#202020]">
        <div
          className={classes(
            'overflow-auto h-[200px] w-full mt-auto pb-4 scrollbar-white',
            isMobile && 'h-[150px]'
          )}
        >
          {OtherOptions.map(({ Icon, label, link, ActiveIcon, pageNavigation }, idx) => {
            const isActive = location.pathname === link;

            return (
              <Link
                to={link}
                target={pageNavigation ? '' : '_blank'}
                key={idx}
                className={classes(
                  'flex flex-row gap-3 w-full h-12 items-center px-6 py-3 relative hover:after:left-0 hover:after:top-0 hover:after:h-full hover:after:w-[3px] hover:bg-[#ffffff0d] hover:after:bg-[#ffffff33] hover:after:rounded-tr-[1px] hover:after:rounded-br-[1px] hover:after:absolute cursor-pointer',
                  isActive &&
                    'bg-primary-gradient-light relative after:left-0 after:top-0 after:h-full after:w-[3px] after:bg-primary-gradient after:rounded-tr-[1px] after:rounded-br-[1px] after:absolute'
                )}
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                <div className="h-[24px] w-[24px] flex items-center justify-center">
                  {isActive && ActiveIcon ? (
                    <ActiveIcon className="min-h-5 min-w-5 max-h-5 max-w-5" />
                  ) : (
                    <Icon className="min-h-5 min-w-5 max-h-5 max-w-5" />
                  )}
                </div>
                <div
                  className={classes(
                    'text-white text-sm font-normal',
                    isActive && 'bg-primary-gradient bg-clip-text text-transparent'
                  )}
                >
                  {label}
                </div>
              </Link>
            );
          })}
          <Link
            to={import.meta.env.VITE_EXPLORER_FRONTEND_URL}
            target="_blank"
            className="flex flex-row gap-3 flex-1 w-[calc(100%-32px)] md:h-12 h-10 items-center p-3 mx-4 rounded-xl border border-solid border-dark-gray cursor-pointer md:mt-[6px] mt-4"
          >
            <BorderedGlobe />
            <div className="flex items-center justify-between flex-1">
              <div className="md:text-sm text-base bg-primary-gradient bg-clip-text text-transparent">
                Explorer
              </div>
              <div className="w-[18px] h-[18px]">
                <GradientArrow />
              </div>
            </div>
          </Link>
          {isMobile && !account?.id && (
            <Link
              to={pathTo('SignIn')}
              className="flex flex-row gap-3 flex-1 w-[calc(100%-32px)] h-10 items-center p-3 mx-4 rounded-xl border-gradient cursor-pointer mt-4 justify-center after:rounded-lg"
              onClick={() => setIsOpen(false)}
            >
              <div className="bg-primary-gradient bg-clip-text text-transparent uppercase text-base">
                Sign In
              </div>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}
