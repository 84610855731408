import { DeveloperQuestSubType } from 'gql';
import { RewardType } from './useDeveloperQuests';

import { QuestTaskButton } from './useCommunityQuests';
import {
  AdopterOatIcon,
  DeveloperOatIcon,
  TestnetOatIcon,
  Tooltip,
} from 'components';

export const useInactiveQuests = () => {
  return [
    {
      heading: 'Community',
      tasks: [
        {
          inactive: true,
          rewardType: RewardType.FirstTime,
          type: DeveloperQuestSubType.ProofOfHumanity,
          title: "Prove you're a human with Proof of Humanity!",
          description:
            "Collect medallions with Intract to prove you're human and earn extra rewards at the end of our incentivized testnet.",
          primaryPoints: 'xATP',
          hideCompletedButton: true,
          taskButton: <QuestTaskButton inactive>Ended</QuestTaskButton>,
        },
        {
          inactive: true,
          rewardType: RewardType.FirstTime,
          type: DeveloperQuestSubType.SignInWithEmail,
          title: 'Sign in with email',
          description: 'You can sign in with Google or create a new account with any email.',
          primaryPoints: '1 ATP',
        },

        {
          inactive: true,
          rewardType: RewardType.FirstTime,
          type: DeveloperQuestSubType.Pixelport,
          title: 'Unlock NFTs with Pixelport x Analog',
          description:
            "Complete minimum 2 Pixelport quests and earn at least 10 ATP from Analog's quest to unlock bonus ATP rewards",
          primaryPoints: '50 ATP',
          claimButton: true,
          taskButton: <QuestTaskButton inactive>Ended</QuestTaskButton>,
        },

        {
          inactive: true,
          rewardType: RewardType.FirstTime,
          type: DeveloperQuestSubType.GalaxeQuest,
          title: 'Participate and complete Analog’s Galxe Campaign',
          description:
            "After completing tasks on Galxe, be sure to connect your EVM wallet then click 'Claim' on the Galxe platform. Then, connect the same EVM wallet you used on Galxe to redeem your points here.",
          primaryPoints: '11 ATP',
          claimButton: true,
          maxTaskPoints: 11,
          taskButton: <QuestTaskButton inactive>Ended</QuestTaskButton>,
        },
        {
          inactive: true,
          rewardType: RewardType.FirstTime,
          type: DeveloperQuestSubType.GalaxeXDysonFinance,
          title: ' Analog x Dyson Finance: Unlock the Future of Finance',
          description:
            "Join this exciting partnership campaign between Analog and Dyson Finance! By completing tasks, you can earn valuable ATP on Analog's testnet platform. Complete the Dyson Surge S2 task to earn even more ATP and the chance to qualify for $DYSN airdrops!",
          primaryPoints: '20 ATP',
          claimButton: true,
          maxTaskPoints: 20,
          taskButton: <QuestTaskButton inactive>Ended</QuestTaskButton>,
        },
        {
          inactive: true,
          rewardType: RewardType.FirstTime,
          type: DeveloperQuestSubType.GalaxeXOatTestnet,
          title: 'Earn with your Early Testnet Registration OAT',
          description: 'Connect your wallet containing the OAT to the platform to earn rewards.',
          questIcon: <TestnetOatIcon />,
          primaryPoints: '10 ATP',
          maxTaskPoints: 10,
          taskButton: <QuestTaskButton inactive>Ended</QuestTaskButton>,
        },
        {
          inactive: true,
          rewardType: RewardType.FirstTime,
          type: DeveloperQuestSubType.GalaxeXOatAdopter,
          title: 'Earn with your Early Adopter OAT',
          description: 'Connect your wallet containing the OAT to the platform to earn rewards.',
          questIcon: <AdopterOatIcon />,
          primaryPoints: '10 ATP',
          maxTaskPoints: 10,
          claimButton: true,
          taskButton: <QuestTaskButton inactive>Ended</QuestTaskButton>,
        },
        {
          inactive: true,
          rewardType: RewardType.FirstTime,
          type: DeveloperQuestSubType.GalaxeXOatDeveloper,
          title: 'Earn with your Developer OAT',
          description: 'Connect your wallet containing the OAT to the platform to earn rewards.',
          questIcon: <DeveloperOatIcon />,
          primaryPoints: '10 ATP',
          maxTaskPoints: 10,
          claimButton: true,
          taskButton: <QuestTaskButton inactive>Ended</QuestTaskButton>,
        },

        {
          inactive: true,
          rewardType: RewardType.FirstTime,
          type: DeveloperQuestSubType.DMail,
          title: 'Sign up with Dmail to claim your cross-chain decentralized email address',
          description: 'Earn points both on Dmail and Analog!',
          primaryPoints: '10 ATP',
          secondaryPoints: '80 DP',
          secondaryPointsLabel: 'Dmail Rewards',
          taskButton: <QuestTaskButton inactive>Ended</QuestTaskButton>,
        },
        {
          inactive: true,
          rewardType: RewardType.FirstTime,
          type: DeveloperQuestSubType.SentEmailOnDmail,
          title: 'Send an email on Dmail with the Analog Network',
          description:
            'Sign into Dmail with Analog using a Substrate wallet and send an email by the Analog Network. Please ensure you use the same Substrate wallet connected here.',
          primaryPoints: '10 ATP',
          taskButton: <QuestTaskButton inactive>Ended</QuestTaskButton>,
        },

        {
          inactive: true,
          rewardType: RewardType.FirstTime,
          type: DeveloperQuestSubType.Talisman,
          title: 'Earn extra points with Talisman quests!',
          description:
            'Use your Talisman wallet to complete quests and earn extra points on Talisman’s quest platform.',
          primaryPoints: '70 XP',
          primaryPointsLabel: 'Talisman Rewards',
          taskButton: <QuestTaskButton inactive>Ended</QuestTaskButton>,
        },
        {
          inactive: true,
          rewardType: RewardType.FirstTime,
          type: DeveloperQuestSubType.Quaere,
          title: 'Quaere x Analog: Daily Tarot Reading',
          description:
            'Earn points both on Quaere and Analog! Please ensure you use the same email on both platforms.',
          primaryPoints: '10 ATP',
          secondaryPoints: '10 PTS',
          secondaryPointsLabel: 'STAR Points',
          taskButton: (
            <>
              <QuestTaskButton inactive buttonTextAfterClick={'Verify'}>
                Ended
              </QuestTaskButton>
            </>
          ),
        },
        {
          inactive: true,
          rewardType: RewardType.FirstTime,
          type: DeveloperQuestSubType.Zealy,
          title: 'Grab your Zealy role on Discord',
          description:
            'Visit Zealy to grab your "Zealy Master" Discord role on the Analog server. You will need to have level 3 (500xp+) or higher on Analog\'s Zealy to be eligible for the role.',
          primaryPoints: '15 ATP',
          taskButton: (
            <>
              <QuestTaskButton inactive buttonTextAfterClick={'Verify'}>
                Ended
              </QuestTaskButton>
            </>
          ),
        },
        {
          rewardType: RewardType.FirstTime,
          type: DeveloperQuestSubType.Intract,
          title: 'Embark on the Analog Incentivized Odyssey!',
          description:
            'Quest is ended! Connect the same EVM wallet you used on Intract to redeem your points here.',
          primaryPoints: '11 ATP',
          secondaryPoints: '130 XP',
          secondaryPointsLabel: 'Intract Points',
          inactive: true,
          taskButton: (
            <QuestTaskButton
              inactive
              handleClick={() => {
                window.open(
                  'https://www.intract.io/quest/6661a09dfcf066cdbc5bec04?utm_source=dashboard',
                  '_blank'
                );
              }}
            >
              Ended
            </QuestTaskButton>
          ),
        },
        {
          rewardType: RewardType.FirstTime,
          type: DeveloperQuestSubType.IntractXMetaStreet,
          title: 'Analog X Metastreet: The Takeover Saga',
          description:
            'Quest is ended! Connect the same EVM wallet you used on Intract to redeem your points here.',
          primaryPoints: '11 ATP',
          secondaryPoints: '90 XP',
          secondaryPointsLabel: 'Intract Points',
          inactive: true,
          taskButton: <QuestTaskButton inactive>Ended</QuestTaskButton>,
        },
        {
          rewardType: RewardType.FirstTime,
          type: DeveloperQuestSubType.GalaxeXCryfiQuest,
          title: 'Analog X Cryfi: $CRFY Initiative Program',
          description:
            'The more tasks you complete, the higher your chances of winning $1000 worth of $CRFY tokens and ATP. Be sure to connect the same EVM wallet you used on Galxe to redeem your points here.',
          primaryPoints: '8 ATP',
          claimButton: true,
          inactive: true,
          taskButton: (
            <QuestTaskButton
              inactive
              handleClick={() =>
                window.open('https://app.galxe.com/quest/analog/GCQCKtxAoj', '_blank')
              }
            >
              Ended
            </QuestTaskButton>
          ),
        },
        {
          rewardType: RewardType.MultipleTime,
          title: 'Participate in a Watch Game Voting Session',
          description: 'You get rewarded for participating.',
          primaryPoints: '10 ATP',
          inactive: true,
          claimButton: true,
          taskButton: <QuestTaskButton>Ended</QuestTaskButton>,
        },
        {
          rewardType: RewardType.MultipleTime,
          title: (
            <div className="flex flex-row gap-1 mb-1 items-center">
              <div>Your vote wins a Voting Session!</div>
              <div
                className="cursor-default text-xs max-h-[21px] min-w-fit flex items-center px-2 py-1 bg-[linear-gradient(140deg,#D274F74D_16.37%,#9A74F74D_50.02%)] rounded-full relative"
                data-tooltip-id="vote-wins-twice-tooltip"
              >
                <Tooltip
                  id="vote-wins-twice-tooltip"
                  content="If you win twice in a row!"
                  classNames="max-w-[80vw] break-words !bg-[#232224] !border-none"
                  classNameArrow="!border-none"
                  rest={{ arrowColor: '#232224' }}
                />
                X2 PTS
              </div>
            </div>
          ),
          description: 'You get rewarded if the View you voted on wins a Voting Session.',
          primaryPoints: '20 ATP',
          inactive: true,
        },
      ],
    },
    {
      heading: 'Developer',
      tasks: [
        {
          rewardType: RewardType.MultipleTime,
          title: 'Your view has been shortlisted in a Voting Session!',
          description:
            'Every time a View you built gets shortlisted for a Voting Session, you get rewarded with 10 ATP.',
          primaryPoints: '10 ATP',
          inactive: true,
        },
        {
          rewardType: RewardType.MultipleTime,
          title: 'Your view wins a Voting Session!',
          description:
            'Every time the View you built wins a Voting Session, you get rewarded with 80 ATP.',
          primaryPoints: '80 ATP',
          inactive: true,
        },
        {
          rewardType: RewardType.FirstTime,
          type: DeveloperQuestSubType.SignInWithWalletOnTestnet,
          title: 'Sign in with your Substrate Wallet to claim your Watch quest points',
          description:
            'Please connect your Substrate wallet used for Watch quests to claim your points.',
          primaryPoints: '1 ATP',
          inactive: true,
        },
        {
          rewardType: RewardType.FirstTime,
          type: DeveloperQuestSubType.ConnectedWalletOnWatch,
          title: 'Connect your Substrate Wallet on the Watch',
          description: 'Please refer to the guides for assistance with Watch wallet connections.',
          primaryPoints: '1 ATP',
          taskButton: <QuestTaskButton inactive>Ended</QuestTaskButton>,
          inactive: true,
        },
        {
          rewardType: RewardType.FirstTime,
          type: DeveloperQuestSubType.CreatedApiKey,
          title: 'Create an API Key',
          description: 'Generate an API key using the Watch SDK or on your Watch Portal profile.',
          primaryPoints: '2 ATP',
          taskButton: <QuestTaskButton inactive>Ended</QuestTaskButton>,
          inactive: true,
        },

        {
          rewardType: RewardType.OnceDaily,
          type: DeveloperQuestSubType.ListedSmartContract,
          title: 'List Smart Contract',
          description: <div>Refer to the guides for help with listing smart contracts.</div>,
          primaryPoints: '5 ATP',
          taskButton: <QuestTaskButton inactive>Ended</QuestTaskButton>,
          inactive: true,
        },
        {
          rewardType: RewardType.OnceDaily,
          type: DeveloperQuestSubType.CreatedView,
          title: 'Build and deploy a View',
          description: <div>Refer to the guides for help with building a View.</div>,
          primaryPoints: '7 ATP',
          taskButton: <QuestTaskButton inactive>Ended</QuestTaskButton>,
          inactive: true,
        },
        {
          rewardType: RewardType.OnceDaily,
          type: DeveloperQuestSubType.SponsorView,
          title: 'Sponsor/Fund a Unique View',
          description: <div>Refer to the guides for help with funding a View.</div>,
          primaryPoints: '10 ATP',
          taskButton: <QuestTaskButton inactive>Ended</QuestTaskButton>,
          inactive: true,
        },

        {
          rewardType: RewardType.OnceDaily,
          type: DeveloperQuestSubType.SubmittedViewForGame,
          title: 'Submit a View for Watch Game',
          description:
            "Create a new View to be considered for shortlisting in the Watch Game, or select one you've already deployed.",
          primaryPoints: '5 ATP',
          taskButton: <QuestTaskButton inactive>Ended</QuestTaskButton>,
          inactive: true,
        },
        {
          rewardType: RewardType.OnceDaily,
          type: DeveloperQuestSubType.SponsorViewQueriedByUser,
          title: 'When your funded View gets queried by a unique user',
          description:
            'The most recent 5 sponsors get rewarded when the view is queried by a unique user. Check your rewards in your profile page in the Funded Views tab.',
          primaryPoints: '5 ATP',
          inactive: true,
        },
        {
          rewardType: RewardType.OnceDaily,
          type: DeveloperQuestSubType.OwnerViewQueriedByUser,
          title: 'When your View gets queried by a unique user',
          description: 'You get rewarded when your View gets queried by a unique user.',
          primaryPoints: '3 ATP',
          inactive: true,
        },
        {
          rewardType: RewardType.OnceDaily,
          type: DeveloperQuestSubType.QueryView,
          onceInADayReward: true,
          title: 'Query a Unique View',
          description: <div>Refer to the guides for help with querying a View. </div>,
          primaryPoints: '15 ATP',
          taskButton: <QuestTaskButton inactive>Ended</QuestTaskButton>,
          inactive: true,
        },
      ],
    },
  ];
};
