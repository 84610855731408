export enum RewardType {
  FirstTime = 'Rewarded 1st time',
  MultipleTime = 'Rewarded Multiple Times',
  OnceDaily = 'Rewarded Once',
  FiveTimeDaily = 'Rewarded Five Times Daily',
}

export const useDeveloperQuests = () => {
  // const { account, userPoints, trackEvent } = useApi();
  // const { isSuccessLogin } = useAuth();
  // const { completedQuests } = useQuestPageData();

  // const [submitUserFeedback] = useSubmittedFeedbackQuestMutation();

  // const submitFeedbackHandler = useCallback(() => {
  //   if (
  //     account?.email &&
  //     !completedQuests.find((quest) => quest?.type === DeveloperQuestSubType.SubmittedFeedback)
  //   )
  //     submitUserFeedback &&
  //       submitUserFeedback({ variables: { email: account.email }, refetchQueries: 'active' });
  // }, [submitUserFeedback, account?.email, completedQuests]);

  return [];
};
