import { Button, ClockIcon, GreenRightIcon, Skeleton } from 'components';
import { useApi } from 'context';
import { CompletedDevQuestCountType, QuestPointsSubType } from 'gql';
import { useWindowSize } from 'hooks';
import { RewardType } from 'hooks/quests';
import { ClassNames, QuestCardProps, QuestTask } from 'types';
import { classes } from 'utils';

const getTaskStatus = (
  task: QuestTask,
  isComingSoon: boolean | undefined,
  completedQuests: (QuestPointsSubType | null)[] | undefined,
  completedCountData: (CompletedDevQuestCountType | null)[] | null | undefined
) => {
  if (task.completed) {
    return { showCompleted: true, isCompleted: true, finishCount: 1 };
  }
  const taskStatus = completedQuests?.find((el) => el?.type === task.type);
  const finishCount = completedCountData?.find((el) => el?.type === task.type)?.count || 0;
  const multiRewardPoints =
    task.rewardType === RewardType.FirstTime
      ? task.maxTaskPoints
        ? task.pointsRewarded || taskStatus?.points
        : undefined
      : task.pointsRewarded || taskStatus?.points;
  let showCompleted =
    task.hide || isComingSoon
      ? false
      : task.maxTaskPoints
      ? taskStatus?.points === task.maxTaskPoints
      : taskStatus;
  let isCompleted;

  switch (task.rewardType) {
    case RewardType.FirstTime: {
      isCompleted = showCompleted;
      break;
    }
    case RewardType.OnceDaily: {
      showCompleted = showCompleted && finishCount > 0;
      isCompleted = showCompleted && finishCount === 1;
      break;
    }
    case RewardType.FiveTimeDaily: {
      showCompleted = showCompleted && finishCount > 0;
      isCompleted = showCompleted && finishCount === 5;
      break;
    }
    case RewardType.MultipleTime: {
      isCompleted = showCompleted || !!task.pointsRewarded;
      break;
    }
  }

  return { finishCount, showCompleted, isCompleted, multiRewardPoints };
};

const QuestTaskCard = ({
  hideAdditionalDescriptionAfterComplete,
  isComingSoon,
  task,
  completedQuests,
  completedCountData,
  classNames,
}: {
  hideAdditionalDescriptionAfterComplete: boolean | undefined;
  isComingSoon: boolean | undefined;
  task: QuestTask;
  completedQuests: (QuestPointsSubType | null)[] | undefined;
  completedCountData: (CompletedDevQuestCountType | null)[] | null | undefined;
  classNames?: ClassNames<''>;
}) => {
  const { multiRewardPoints, showCompleted, isCompleted, finishCount } = getTaskStatus(
    task,
    isComingSoon,
    completedQuests,
    completedCountData
  );
  const { windowSize } = useWindowSize();

  if (task.cardComponent && !isCompleted) {
    return <task.cardComponent isCompleted={!!isCompleted} task={task} />;
  }

  return (
    <div
      className={classes(
        'flex bg-black border-solid border-steel-gray shadow-primary-shadow rounded-lg border-[0.5px] relative min-h-[90px]',
        isCompleted &&
          'bg-[linear-gradient(0deg,_var(--tw-gradient-stops))] from-[#21b36813_0%] to-[#21b36813_100%] border-[#21B368] border-[0.5px]',
        !task.hide && 'hover:bg-[#0d0d0dc0]',
        task.additionalDescription && 'gap-0',
        task.inactive && 'grayscale'
      )}
    >
      {(showCompleted || isCompleted) && !(task.rewardType === RewardType.MultipleTime) && (
        <div
          className={classes(
            'flex items-center justify-center gap-[2px] px-2 md:py-1 py-[2px] bg-opacity-10 rounded-[46px] border min-w-[120px] max-w-[120px] backdrop-blur-[14px] h-fit absolute md:top-[-15px] top-[-13px] left-1/2 translate-x-[-50%]',
            isCompleted
              ? 'bg-green-500 border-green-500'
              : 'border-[#9a74f7] bg-[#987ed721] border after:rounded-[46px]'
          )}
        >
          {isCompleted ? <GreenRightIcon /> : <GreenRightIcon stroke="#9a74f7" />}
          <div
            className={classes(
              "text-center text-green-500 text-[10px] font-medium font-['Chakra Petch'] uppercase leading-[16px]",
              !isCompleted && 'text-[#9a74f7]'
            )}
          >
            {task.rewardType === RewardType.OnceDaily
              ? `Completed - ${finishCount}/1`
              : task.rewardType === RewardType.FiveTimeDaily
              ? `Completed - ${finishCount}/5`
              : 'Completed'}
          </div>
        </div>
      )}
      {task.hide && (
        <div className="w-full h-full top-0 right-0 min-h-[92px] flex items-center justify-between gap-4 bg-black bg-opacity-40 border-solid border-steel-gray shadow-primary-shadow rounded-lg  border-[0.5px] absolute">
          <div className="bg-black bg-opacity-40 backdrop-blur-sm w-full h-full flex items-center justify-center flex-col md:gap-2 gap-1">
            <div className="bg-[#1C1C1C] md:p-2 p-[6px] rounded-md">
              <ClockIcon className="h-6 w-6" />
            </div>
            <div className="md:text-xl text-lg">Coming Soon</div>
          </div>
        </div>
      )}
      <div
        className={classes(
          'md:px-6 px-4 sm:py-4 py-2 flex justify-center items-center gap-4 rounded-[7px_0px_0px_7px] lg:flex-row flex-col',
          classNames?.base,
          isCompleted
            ? 'bg-[#21B368]'
            : 'bg-[linear-gradient(140deg,_rgba(210,_116,_247,_0.50)_16.37%,_rgba(154,_116,_247,_0.50)_50.02%)]'
        )}
      >
        <div className="flex flex-col min-w-max items-center">
          <div
            className={classes(
              'md:text-xl text-lg font-text-bold text-center',
              task.secondaryPoints && '!text-lg'
            )}
          >
            <Skeleton.Loader className="w-12 h-6">{task.primaryPoints}</Skeleton.Loader>
          </div>
          <div
            className={classes(
              'text-[#ffffffcc] md:text-sm text-xs md:-mt-[2px] -mt-[4px]',
              task.secondaryPoints && '!text-xs'
            )}
          >
            <Skeleton.Loader className="w-[55px] h-4">
              {task.primaryPointsLabel || 'Rewards'}
            </Skeleton.Loader>
          </div>
        </div>
        {task.secondaryPoints && (
          <div className="flex flex-col min-w-max items-center">
            <div className="text-lg font-text-bold text-center">
              <Skeleton.Loader className="w-12 h-6">{task.secondaryPoints}</Skeleton.Loader>
            </div>
            <div className="text-[#ffffffcc] text-xs md:-mt-[2px] -mt-[4px]">
              <Skeleton.Loader className="w-[55px] h-4">
                {task.secondaryPointsLabel}
              </Skeleton.Loader>
            </div>
          </div>
        )}
      </div>
      <div className="w-full">
        <div
          className={classes(
            'flex lg:flex-row flex-col justify-between lg:items-center items-start gap-[11px] xl:gap-0  py-4 px-6 h-full w-full',
            task.additionalDescription && 'h-fit border-solid border-b w-full border-[#202020]'
          )}
        >
          <div className="flex gap-4 items-center">
            {!!task.questIcon && windowSize.width > 560 && <div>{task.questIcon}</div>}
            <div className="flex flex-col lg:pb-0 xl:pr-6">
              {windowSize.width > 560 && (
                <>
                  <div className="text-[10px] text-gray uppercase md:mb-[2px] leading-4 flex items-center md:gap-1 gap-[2px] flex-wrap">
                    <Skeleton.Loader className="max-w-[100px] min-w-[75px] h-[14px]">
                      <div className="mt-auto">{task.rewardType}</div>
                      {multiRewardPoints && (
                        <div className="text-center text-green-500 text-[10px] font-medium uppercase leading-none gap-1 flex items-center">
                          <span className="h-[2px] w-[2px] flex items-center justify-center bg-green-500 rounded-full"></span>
                          <div className="uppercase">rewarded {multiRewardPoints} pt</div>
                        </div>
                      )}
                    </Skeleton.Loader>
                  </div>
                  <div className=" text-white md:text-base text-sm font-normal font-['Chakra Petch'] md:leading-normal">
                    <Skeleton.Loader className="max-w-[226px] md:min-w-[200px] min-w-[100px] h-[22px]">
                      {isComingSoon || task.hide
                        ? 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Id, rem.'
                        : task.title}
                    </Skeleton.Loader>
                  </div>
                </>
              )}
              {windowSize.width < 560 && (
                <div className="flex items-center">
                  {!!task.questIcon && windowSize.width < 560 && (
                    <div className="transform scale-50 -ml-4">{task.questIcon}</div>
                  )}
                  <div>
                    <div className="text-[10px] text-gray uppercase md:mb-[2px] leading-4 flex items-center md:gap-1 gap-[2px] flex-wrap">
                      <Skeleton.Loader className="max-w-[100px] min-w-[75px] h-[14px]">
                        <div className="mt-auto">{task.rewardType}</div>
                        {multiRewardPoints && (
                          <div className="text-center text-green-500 text-[10px] font-medium uppercase leading-none gap-1 flex items-center">
                            <span className="h-[2px] w-[2px] flex items-center justify-center bg-green-500 rounded-full"></span>
                            <div className="uppercase">rewarded {multiRewardPoints} pt</div>
                          </div>
                        )}
                      </Skeleton.Loader>
                    </div>
                    <div className=" text-white md:text-base text-sm font-normal font-['Chakra Petch'] md:leading-normal">
                      <Skeleton.Loader className="max-w-[226px] md:min-w-[200px] min-w-[100px] h-[22px]">
                        {isComingSoon || task.hide
                          ? 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Id, rem.'
                          : task.title}
                      </Skeleton.Loader>
                    </div>
                  </div>
                </div>
              )}
              <Skeleton.Loader className="max-w-[353px] md:min-w-[300px] min-w-[100px] h-[18px]">
                {task.description && (
                  <div className={`text-gray text-xs sm:text-justify text-left mt-1 sm:mt-0`}>
                    {isComingSoon || task.hide
                      ? 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Id, rem.'
                      : task.description}
                  </div>
                )}
              </Skeleton.Loader>
            </div>
          </div>
          {(!task.hideCompletedButton || !isCompleted) &&
            (isCompleted && !(task.rewardType === RewardType.MultipleTime) && task.taskButton ? (
              <Button
                variant="plain"
                classNames={{
                  base: 'max-h-10 after:hidden h-[unset] [background:linear-gradient(140deg,_#d274f70d_16.37%,_#9a74f70d_50.02%)#000] border border-solid border-[#21B368] rounded-lg h-[unset] px-3 !py-[9px] lg:min-w-[172px] w-full lg:max-w-[172px] cursor-default !bg-none',
                  container: 'flex items-center lg:gap-[6px] gap-1',
                }}
              >
                <GreenRightIcon />
                <div className="text-center text-[#21B368] font-text-bold md:text-sm text-xs md:leading-[22px] leading-[18px] uppercase">
                  Completed
                </div>
              </Button>
            ) : (
              (task.taskButton || task.secondaryTaskButton) && (
                <div className="flex xl:gap-4 gap-3 self-center xl:flex-row flex-col lg:w-fit w-full">
                  {task.secondaryTaskButton}
                  {task.taskButton}
                </div>
              )
            ))}
          {task.showTaskButton && isCompleted && task.taskButton}
        </div>
        {(!hideAdditionalDescriptionAfterComplete || (task.maxTaskPoints && !isCompleted)) &&
          task.additionalDescription}
      </div>
    </div>
  );
};

const QuestCard = ({
  quest,
  connectWalletHandler,
  blockCondition,
  completedQuests,
  completedCountData,
  hideAdditionalDescriptionAfterComplete,
  classNames,
}: QuestCardProps) => {
  const { trackEvent } = useApi();
  return (
    <div className="flex flex-col gap-3">
      <div className="flex flex-row items-center gap-[10px]">
        {quest.headingIcon && (
          <div className="md:text-xl text-base uppercase grayscale">
            <Skeleton.Loader className="h-7 w-7">{quest.headingIcon}</Skeleton.Loader>
          </div>
        )}
        {quest.heading && (
          <div className="md:text-xl text-base uppercase">
            <Skeleton.Loader className="h-7 w-[120px]">{quest.heading}</Skeleton.Loader>
          </div>
        )}
        {quest.headCta && (
          <div className="ml-auto">
            <Skeleton.Loader className="h-7 w-[120px]">{quest.headCta}</Skeleton.Loader>
          </div>
        )}
      </div>
      <div
        className={classes(
          'flex flex-col gap-5 relative',
          (quest.comingSoon || (blockCondition && quest.blockText)) && 'overflow-hidden'
        )}
      >
        {quest.tasks.map((task, index) => {
          return (
            <QuestTaskCard
              task={task}
              isComingSoon={quest.comingSoon}
              hideAdditionalDescriptionAfterComplete={hideAdditionalDescriptionAfterComplete}
              completedQuests={completedQuests}
              completedCountData={completedCountData}
              key={index}
              classNames={classNames}
            />
          );
        })}
        {quest.comingSoon ? (
          <div className="absolute top-0 left-0 bg-black bg-opacity-40 backdrop-blur-sm w-full h-full flex items-center justify-center flex-col md:gap-2 sm:gap-1 gap-2">
            <Skeleton.Loader className="w-9 h-9">
              <div className="bg-[#1C1C1C] md:p-2 sm:p-1 p-2 rounded-md">
                <ClockIcon className="h-7 w-7" />
              </div>
            </Skeleton.Loader>
            <Skeleton.Loader className="h-5 w-24">
              <div className="md:text-xl text-lg">Coming Soon</div>
            </Skeleton.Loader>
          </div>
        ) : (
          blockCondition &&
          quest.blockText && (
            <div className="absolute top-0 left-0 bg-black bg-opacity-40 backdrop-blur-sm w-full h-full flex items-center justify-center flex-col gap-3">
              <div className="max-w-[374px] text-center text-white text-base font-normal font-['Chakra Petch'] leading-normal">
                {quest.blockText}
              </div>
              <Button
                variant="primary"
                onClick={() => {
                  connectWalletHandler && connectWalletHandler();
                  trackEvent('gtm_wallet_connect', {
                    action: 'start_connect_substrate_account',
                  });
                }}
                className="h-9"
              >
                Connect
              </Button>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default QuestCard;
