export const AnnouncementBanner = () => {
  return (
    <div className="relative overflow-hidden bg-[#ffffff0d] lg:text-sm text-xs text-white w-full text-center xs:tracking-[1.4px] tracking-normal lg:py-[10px] mg:py-[6px] py-1 px-4 border-b border-solid border-dark-gray">
      <div
        className="absolute h-full w-full  top-0 left-0 before:opacity-[0.18] before:content-[' '] before:absolute before:w-[180px] before:h-[180px] before:rounded-full before:bg-[linear-gradient(140deg,#D274F7_16.37%,#9A74F7_50.02%)] before:blur-[50px] before:-top-[50px] before:left-[105px] before:z-10
      after:content-[' '] after:absolute after:w-[180px] after:opacity-[0.18] after:h-[180px] after:rounded-full after:bg-[linear-gradient(140deg,#D274F7_16.37%,#9A74F7_50.02%)] after:blur-[50px] after:-top-[50px] after:right-[105px] after:z-10 "
      />
      <div className="absolute h-full w-full bg-[url(/bannerBg.svg)] bg-cover bg-no-repeat top-0 left-0 opacity-[0.05]" />
      <div>
      🎉 The Incentivized Testnet quests have ended! Please connect your Substrate wallet to get ready for your upcoming reward!
      </div>
    </div>
  );
};
