import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Button,
  ExclamationMark,
  ExclamationWarringMark,
  BackIcon,
  Loader,
  Modal,
} from 'components';
import { useApi } from 'context';
import { InjectedAccountWithMeta } from 'types';
import { Identicon } from 'components/Identicon';
import { useConnectWalletMutation, useSwitchSubstrateWalletMutation } from 'gql';
import { useCookies } from 'react-cookie';
import { classes, customToast, desktopWallet, truncate } from 'utils';
import { useWindowSize } from 'hooks';

enum FlowType {
  switch = 'switch-wallet',
  connect = 'connect-wallet',
}

export const ConnectWallet = () => {
  const [{ accessToken }] = useCookies(['accessToken']);
  const { isMobile } = useWindowSize();
  const { account, isConnecting } = useApi();
  const [searchParams, setSearchParams] = useSearchParams();

  const [connectWallet, { error: ConnectWalletError, reset: resetConnectWalletMutation }] =
    useConnectWalletMutation();
  const [switchWallet, { error: SwitchWalletError, reset: resetSwitchWalletMutation }] =
    useSwitchSubstrateWalletMutation();
  const {
    walletAccount,
    walletAccountOptions,
    setWalletAccount,
    trackEvent,
    onConfirm,
    onConnect,
  } = useApi();
  const [flowType, setFlowType] = useState<FlowType>();
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
  const [confirmText, setConfirmText] = useState<string | null>(null);
  const [selectedWalletOption, setSelectedWalletOption] = useState<InjectedAccountWithMeta | null>(
    null
  );

  const isOpen = useMemo(() => {
    if (searchParams.get('connect-wallet') !== null) {
      setFlowType(FlowType.connect);
      return true;
    } else if (searchParams.get('switch-wallet') !== null) {
      setFlowType(FlowType.switch);
      return true;
    }

    return false;
  }, [searchParams]);

  const closeModel = useCallback(() => {
    if (flowType) {
      searchParams.delete(flowType);
      setSearchParams(searchParams);
    }
  }, [searchParams, flowType]);

  useEffect(() => {
    if (isOpen) {
      onConnect && onConnect();
      setSelectedWalletOption(null);
      resetConnectWalletMutation();
      resetSwitchWalletMutation();
      setWalletAccount('');
      setIsConfirmed(false);
      setConfirmText(null);
    } else if (flowType) {
      searchParams.delete(flowType);
      setSearchParams(searchParams);
    }
  }, [isOpen]);

  useEffect(() => {
    if (flowType === FlowType.connect) resetConnectWalletMutation();
    if (flowType === FlowType.switch) resetSwitchWalletMutation();
  }, [walletAccount]);

  const content = useMemo(() => {
    if (flowType === FlowType.switch && !isConfirmed) {
      return (
        <div className="w-full h-fit">
          <div className="sm:text-xl text-lg">
            <div className="flex items-center gap-1 ">
              <ExclamationMark
                stroke="white"
                className="p-2 md:min-h-10 md:min-w-10 min-h-9 min-w-9 bg-black-100 rounded-full"
              />
              <div className="md:text-xl text-lg">Warning</div>
            </div>
          </div>
          <div className="text-gray text-sm md:mt-2 mt-3">
            This process will not impact your ATP score and is only needed for verification to claim
            your rewards on the claims portal. Once connected, you won&apos;t be able to change your
            wallet again.
          </div>
          <div className="text-gray text-sm mt-[10px] mb-2">
            Type <span className="text-white">SWITCH</span> below to continue.
          </div>
          <div
            className={classes(
              'bg-black w-full flex justify-between gap-[13px] md:rounded-lg rounded-md md:py-[9px] py-2 px-3 border-[0.5px] border-solid border-[#393939] text-sm shadow-primary-shadow'
            )}
          >
            <input
              className="w-full autofill:bg-clip-text autofill:shadow-[inset 0_0_20px_20px_#23232300] autofill:[-webkit-text-fill-color:#fff]"
              placeholder="SWITCH"
              value={confirmText || ''}
              onChange={(e) => setConfirmText((prev) => e.target.value.replaceAll(' ', ''))}
            />
          </div>

          <div className="flex md:flex-row flex-col justify-between md:gap-6 gap-4 md:mt-6 mt-4">
            <Button className="flex-1" onClick={() => closeModel()}>
              Cancel
            </Button>
            <Button
              variant="primary"
              className="flex-1"
              isDisabled={confirmText !== 'SWITCH'}
              onClick={() => {
                trackEvent('gtm_wallet_change', {
                  action: 'switch_wallet_continue_warning',
                });
                setIsConfirmed(true);
              }}
            >
              Continue
            </Button>
          </div>
        </div>
      );
    }

    if (!walletAccount) {
      return (
        <div className="w-full gap-4 flex flex-col scrollbar-white min-h-[100px]">
          <div className="w-full h-fit">
            <div className="sm:text-2xl text-xl">Connect a Wallet</div>
            <div className="text-gray text-sm mt-1 mb-4">
              Select the subtrate based account you want to connect.
            </div>
            <div className="flex flex-col md:gap-3 gap-2 max-h-[calc(100vh_-_250px)] overflow-y-scroll scrollbar-white pr-2">
              {desktopWallet.map(({ value, img, name }) => {
                return (
                  <button
                    key={value}
                    className={classes(
                      'md:min-h-[70px] min-h-[60px] flex w-full items-center overflow-hidden text-ellipsis px-4 py-3 rounded-2xl border border-[#ffffff1f] hover:bg-[#ffffff0d] hover:border-[#ffffff0d] gap-4 last:mb-0'
                    )}
                    onClick={() => {
                      setWalletAccount(value);
                      onConnect(null, value);
                    }}
                  >
                    <img src={img} alt={value} className="rounded-lg md:h-14 md:w-14 h-10 w-10" />
                    <span>{name}</span>
                  </button>
                );
              })}
            </div>
          </div>
        </div>
      );
    }

    if (flowType === FlowType.connect && selectedWalletOption) {
      return (
        <div className="w-full h-fit">
          <div className="sm:text-xl text-lg">
            <div className="flex items-center gap-1">
              <ExclamationMark
                className="p-2 md:min-h-10 md:min-w-10 min-h-9 min-w-9 bg-black-100 rounded-full"
                stroke="white"
              />
              <div className="md:text-xl text-lg">Warning</div>
            </div>
          </div>
          <div className="text-gray text-sm md:mt-2 mt-3">
            Before proceeding, if you’ve previously used Analog Watch, kindly verify that this is
            the wallet you’ve connected. Please note that switching accounts will lead to the loss
            of accumulated points associated with the wallet.
          </div>
          <div className="flex md:flex-row flex-col justify-between md:gap-6 gap-4 md:mt-6 mt-4">
            <Button className="flex-1" onClick={() => setSelectedWalletOption(null)}>
              Disconnect Your wallet
            </Button>
            <Button
              variant="primary"
              className="flex-1"
              onClick={async () => {
                onConfirm(selectedWalletOption, async (account) => {
                  const { errors } = await connectWallet({
                    variables: { walletAddress: account.address, accessToken: accessToken },
                    refetchQueries: ['GetUser'],
                    onCompleted() {
                      trackEvent('gtm_wallet_connect', {
                        action: 'connect_wallet_account_successfully',
                      });
                      trackEvent('gtm_wallet_connect', {
                        walletType: selectedWalletOption.meta.source,
                      });
                      customToast('Substrate account connect successfully');
                    },
                  });
                  if (!errors) {
                    closeModel();
                  }
                });

                trackEvent('gtm_wallet_connect', {
                  action: 'connect_wallet_warning_continue',
                });
                setSelectedWalletOption(null);
              }}
            >
              Continue
            </Button>
          </div>
        </div>
      );
    }

    return (
      <div className="gap-4 flex flex-col scrollbar-white min-h-[100px] w-full">
        <div className="w-full h-fit">
          <div className="flex md:gap-2 gap-1 items-center">
            <BackIcon
              className="text-white cursor-pointer h-[16px] w-[16px]"
              onClick={() => {
                if (walletAccount) setWalletAccount('');
              }}
            />{' '}
            <div className="sm:text-[18px] text-[16px]">Connect Account</div>
          </div>
          {isConnecting ? (
            <div className="py-14 px-8">
              <Loader className="flex  items-center text-white mb-4" />
              <div className="text-center text-white text-base font-normal leading-relaxed">
                Loading...
              </div>
            </div>
          ) : (
            <div className="flex flex-col gap-2 max-h-[calc(100vh_-_200px)] mt-4 overflow-y-scroll scrollbar-white pr-2">
              {walletAccountOptions?.length ? (
                walletAccountOptions.map((option) => {
                  const { address, meta } = option;
                  return (
                    <button
                      key={address}
                      className={classes(
                        ' mb-2 flex max-w-full items-center text-ellipsis px-4 py-2 rounded-2xl border border-[#ffffff1f] hover:bg-[#ffffff0d] hover:border-[#ffffff0d] last:mb-0 focus:border-white',
                        account?.walletAddress === address && 'bg-[#ffffff0d] cursor-not-allowed'
                      )}
                      onClick={() => {
                        trackEvent('gtm_wallet_connect', {
                          action: 'select_walllet_account',
                          option,
                        });
                        setSelectedWalletOption(option);
                        if (flowType === FlowType.switch) {
                          onConfirm(option, async (account) => {
                            const { errors } = await switchWallet({
                              variables: {
                                newWalletAddress: account.address,
                                accessToken: accessToken,
                              },
                              refetchQueries: 'all',
                            });
                            if (!errors) {
                              trackEvent('gtm_wallet_change', {
                                action: 'switch_wallet_account_successfully',
                              });
                              trackEvent('gtm_wallet_change', {
                                walletType: option.meta.source,
                              });
                              customToast('Substrate account changed successfully');
                              closeModel();
                            }
                          });
                        }
                      }}
                      disabled={account?.walletAddress === address}
                    >
                      <Identicon className="mr-3 h-8 w-8" value={address} />
                      <div className="flex-1 overflow-hidden">
                        <div className="text-lg font-medium">{meta.name}</div>
                        <div className="w-full max-w-full overflow-hidden whitespace-nowrap font-mono text-sm text-neutral-500">
                          {truncate(address, isMobile ? 8 : 17)}
                        </div>
                      </div>
                    </button>
                  );
                })
              ) : (
                <div className="flex flex-col items-center justify-center text-center">
                  <ExclamationWarringMark className="md:h-[120px] md:w-[120px] h-[90px] w-[90px]" />
                  <span className="md:text-2xl text-xl md:mt-[21px] mt-[19px]">
                    You don&apos;t have any account for this wallet
                  </span>
                  <div className="text-gray text-sm mt-1 md:mb-[20px] mb-4">
                    For Watch Quests, we exclusively support Substrate wallets. Find detailed
                    guidance in our documentation for assistance.
                  </div>
                  <Button
                    variant="primary"
                    className="!w-full"
                    onClick={() => {
                      window.open(
                        'https://docs.analog.one/documentation/developers/analog-watch/developers-guide/wallet-support',
                        '_blank'
                      );
                    }}
                  >
                    Documentation
                  </Button>
                </div>
              )}
            </div>
          )}
          {(SwitchWalletError || ConnectWalletError) && (
            <div className="mt-2 text-sm text-red-500">
              Error: {SwitchWalletError?.message || ConnectWalletError?.message}
            </div>
          )}
        </div>
      </div>
    );
  }, [
    walletAccount,
    selectedWalletOption,
    walletAccountOptions,
    confirmText,
    flowType,
    onConnect,
    onConfirm,
    trackEvent,
    connectWallet,
    switchWallet,
    closeModel,
    accessToken,
    isConnecting,
    isConfirmed,
    account?.walletAddress,
    ConnectWalletError,
    SwitchWalletError,
  ]);

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={closeModel}
      classNames={{
        base: 'z-50 bg-black md:p-8 sm:p-6 p-5 rounded-3xl text-white border-white border-opacity-5 w-full sm:w-[510px] max-h-[100%]',
        closeIcon: 'md:top-[35px] top-[17px] md:right-[25px] right-[10px]',
      }}
      isCloseInside
    >
      {content}
    </Modal>
  );
};
