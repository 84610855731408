import Sessions from './Sessions';
import { Overview, Skeleton, Star2Icon, WinsIcon } from 'components';
import { useCookies } from 'react-cookie';
import { useVotingSessionPointsQuery } from 'gql';
import { useApi } from 'context';
// import { SocialConnectBanner } from 'components/SocialConnectBanner';

export function Game() {
  const { userPoints } = useApi();
  const [{ accessToken }] = useCookies(['accessToken']);
  const { data: userSessionPoints, loading: userPointsLoading } = useVotingSessionPointsQuery({
    variables: { accessToken },
    skip: !accessToken,
  });

  return (
    <Skeleton.Provider isLoading={userPointsLoading}>
      <div className="flex flex-col gap-[60px] w-full md:py-[60px] py-[36px] ">
        {/* <SocialConnectBanner className="md:-mb-5" /> */}
        <Overview
          sections={[
            {
              icon: <WinsIcon />,
              label: 'No. of Wins',
              data: userSessionPoints?.votingSessionPoints?.winsAsVoter || '0',
            },
            {
              icon: <Star2Icon />,
              label: 'Watch Game Points',
              data:
                (userPoints?.UserPoints?.viewShortListed || 0) +
                  (userPoints?.UserPoints?.votingSessionParticipated || 0) +
                  (userPoints?.UserPoints?.winnerAsDesigner || 0) +
                  (userPoints?.UserPoints?.winnerAsVoter || 0) || '0',
              dataUnit: 'ATP',
            },
          ]}
        />
        <Sessions />
      </div>
    </Skeleton.Provider>
  );
}
