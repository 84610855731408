import { Header, Profile2Icon, Leaderboard, Skeleton, WinCup, WinStar, WinMedal } from 'components';
import { useLeaderBoardQuery } from 'gql';
import { usePageFilter, useWindowSize } from 'hooks';
import { LeaderboardCard } from './LeaderboardCard';
import { useMemo } from 'react';
import { classes } from 'utils';
import { useApi } from 'context';
// import { SocialConnectBanner } from 'components/SocialConnectBanner';

export function LeaderboardPage() {
  const { isMobile } = useWindowSize();
  const { account, userPoints } = useApi();
  const { data: leaderBoardData, loading: isLoading } = useLeaderBoardQuery({
    fetchPolicy: 'cache-and-network',
    pollInterval: 1000 * 30,
  });

  const myRank = useMemo(
    () => ({
      rank: account?.rank,
      name: account?.name,
      points: userPoints?.UserPoints?.total || 0,
      showYou: true,
    }),
    [account, userPoints?.UserPoints?.total]
  );

  const { controller, currentPage } = usePageFilter(
    leaderBoardData?.leaderBoard,
    10,
    ['10', '20', '30', '100'],
    {
      base: 'mt-0',
    }
  );

  const [isUserInCurrentPage, dataWithUserRank] = useMemo(() => {
    if (!currentPage.length) {
      return [false, []];
    }
    const userPos = [...currentPage].find(
      (row) => row?.name?.toLowerCase() === myRank.name?.toLowerCase()
    );
    if (userPos) {
      return [true, [...currentPage]];
    } else if (myRank.rank) {
      return [false, [...currentPage, myRank]];
    } else {
      return [false, [...currentPage]];
    }
  }, [currentPage, myRank]);

  return (
    <Skeleton.Provider isLoading={isLoading}>
      <div className="flex flex-col md:py-[60px] py-9">
        {/* <SocialConnectBanner className="md:mb-[40px] mb-[32px]" /> */}
        <div className="flex lg:flex-row md:flex-row flex-col justify-between lg:mb-0 md:mb-0 mb-4">
          <div className="flex flex-col lg:mb-6 md:mb-6 mb-4">
            <Header>Leaderboard Top 100</Header>
            <div className="text-gray text-sm leading-[normal] mt-2">
              <Skeleton.Loader className="w-[447px] h-5">
                Check your ranking and accumulated ATP points in the competition
              </Skeleton.Loader>
            </div>
          </div>
        </div>
        {isMobile ? (
          <LeaderboardCard
            data={dataWithUserRank || []}
            defaultPageSize={10}
            isLoading={isLoading}
            myRank={myRank?.rank}
          />
        ) : (
          <Leaderboard
            columns={[
              {
                title: 'Place',
                key: 'rank',
                render(data) {
                  return (
                    <div
                      className={classes(
                        'flex flex-row gap-[6px] items-center w-full',
                        isUserInCurrentPage &&
                          data[data.columnKey] == myRank.rank &&
                          data.name == myRank?.name &&
                          'activeRowChild'
                      )}
                    >
                      <Skeleton.Loader className="w-[7px] h-5">
                        <span
                          className={classes(
                            (data[data.columnKey] === 1 ||
                              data[data.columnKey] === 2 ||
                              data[data.columnKey] === 3) &&
                              'bg-[linear-gradient(140deg,_var(--tw-gradient-stops))] from-[#D274F7_16.37%] to-[#9A74F7_50.02%]  bg-clip-text text-transparent'
                          )}
                        >
                          #{data[data.columnKey]}
                        </span>
                        {data[data.columnKey] === 1 && (
                          <div className=" p-1 rounded-full bg-[#ffffff0d] ">
                            <WinCup />
                          </div>
                        )}
                        {data[data.columnKey] === 2 && (
                          <div className=" p-1 rounded-full bg-[#ffffff0d] ">
                            <WinStar />
                          </div>
                        )}
                        {data[data.columnKey] === 3 && (
                          <div className=" p-1 rounded-full bg-[#ffffff0d] ">
                            <WinMedal />
                          </div>
                        )}
                      </Skeleton.Loader>
                    </div>
                  );
                },
              },
              {
                title: 'Account',
                key: 'account',
                render(data) {
                  return (
                    <div className="flex flex-row gap-[6px] items-center w-full">
                      <Skeleton.Loader className="h-7 w-7 !rounded-full">
                        <Profile2Icon className="min-h-7 min-w-7" />
                      </Skeleton.Loader>
                      <div className="flex flex-col w-[80%]">
                        <div className="text-sm">
                          <Skeleton.Loader className="w-[122px] h-[18px]">
                            {data?.name}
                          </Skeleton.Loader>
                        </div>
                      </div>
                    </div>
                  );
                },
              },
              {
                title: 'Total Points',
                key: 'points',
                render(data) {
                  return (
                    <div className="text-sm">
                      <Skeleton.Loader className="w-[53px] h-5">
                        <span className="text-base text-white">{data[data.columnKey]}</span>{' '}
                        <span className="text-xs text-gray">ATP</span>
                      </Skeleton.Loader>
                    </div>
                  );
                },
              },
              {
                key: 'showYou',
                render(data) {
                  return data[data.columnKey] ||
                    (isUserInCurrentPage &&
                      data.rank == myRank?.rank &&
                      data.name == myRank?.name) ? (
                    <div className="flex justify-center items-center text-xs h-6 w-12 rounded-[28px] bg-[linear-gradient(178deg,_var(--tw-gradient-stops))] from-[#d274f780_16.37%] to-[#9a74f780_50.02%]">
                      YOU
                    </div>
                  ) : null;
                },
              },
            ]}
            data={dataWithUserRank}
            classNames={{
              base: 'overflow-visible',
              row: 'px-6 grid xl:grid-cols-[30%_40%_25%_5%] grid-cols-[25%_35%_25%_15%] activeParent',
              bodyWrapper: classes(
                currentPage.length && !isUserInCurrentPage && account?.rank
                  ? '[&>*:nth-last-child(1)]:[border:1px_solid_#d274f780] [&>*:nth-last-child(1)]:bg-[linear-gradient(178deg,_var(--tw-gradient-stops))] [&>*:nth-last-child(1)]:from-[#d274f761_16.37%] [&>*:nth-last-child(1)]:to-[#9a74f761_50.02%]'
                  : '[&>*:nth-last-child(1)]:border-b'
              ),
              headingBase: 'pt-3 pb-[10px] pl-0',
            }}
            isLoading={isLoading}
          />
        )}
        <div
          className={classes(
            isMobile
              ? 'mt-2'
              : 'px-6 py-1 border border-solid border-t-0  border-steel-gray rounded-b-[10px]'
          )}
        >
          {controller}
        </div>
      </div>
    </Skeleton.Provider>
  );
}
